import React, { useState, useContext, useEffect } from "react";
import "./Home.css";
import HeroSection from "../../components/Hero/HeroSection";
import CardProdutos from "../../components/Card/Produtos/CardProdutos";
import TiposCabelo from "../../components/TiposCabelo/TiposCabelo";
import Tags from "../../components/Tags/Index.js";
import { Context as AuthContext } from "../../Context/AuthContext.js";
import NewsLetter from "../../components/Card/NewsLetter/NewsLetter.jsx";
import api from "../../api/api.js";
import WhatsAppButton from "../../components/WhatsAppButton.jsx";
import DOMPurify from "dompurify";
import GeneralContext from "../../Context/ProductsContext.js";
import ScrollToTop from "../../components/ScrollToTop.jsx";

const Home = () => {
  const { user, setUser, setAllProducts, allProducts } =
    useContext(GeneralContext);
  const { authenticated } = useContext(AuthContext);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [tiposData, setTiposData] = useState([]);
  const [faixaTexto, setFaixaTexto] = useState(null);
  const [banners, setBanners] = useState([]);
  const [carrossel, setCarrossel] = useState({});

  useEffect(() => {
    if (authenticated) {
      api.get("/me").then((res) => {
        setUser(res.data);
      });
    }
  }, [authenticated]);

  useEffect(() => {
    api.get("/banners").then((response) => {
      // Filtra os banners para incluir apenas aqueles com elementos no carousel
      const filteredBanners = response.data.banners.filter(
        (banner) => banner.type === "CAROUSEL"
      );
      // Extrai o carousel dos banners filtrados
      const extractedCarousels = filteredBanners.flatMap(
        (banner) => banner.carousel
      );
      setCarrossel(extractedCarousels);

      const standardBanners = response.data.banners.filter(
        (banner) => banner.type === "STANDARD"
      );
      setBanners(standardBanners);
    });
  }, []);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/products/featured");
        setFeaturedProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
      }
    };

    fetchFeaturedProducts();
  }, []);

  useEffect(() => {
    api
      .get("/products?take=300")
      .then((res) => {
        setAllProducts(res.data.products);
      })
      .catch((err) => console.error(err));
    const fetchFeaturedProducts2 = async () => {
      try {
        const response = await api.get("/banners/body");
        setTiposData(response);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error);
      }
    };

    fetchFeaturedProducts2();
    scrollToTop();
  }, []);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/banners/line");

        // Define o estado apenas com esse objeto, ou null se não encontrado
        const banner = authenticated
          ? user?.isCompany
            ? response.data.inLineBanners.find(
                (b) =>
                  b.type === "FOR_BUSINESS" && b.position === "ABOVE_HEADER"
              )
            : response.data.inLineBanners.find(
                (b) => b.type === "FOR_YOU" && b.position === "ABOVE_HEADER"
              )
          : response.data.inLineBanners.find(
              (b) => b.type === "FOR_YOU" && b.position === "ABOVE_HEADER"
            );
        setFaixaTexto(banner);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error);
      }
    };
    const fetchFeaturedProducts2 = async () => {
      try {
        const response = await api.get("/banners/body");
        console.log(response.data);
        setTiposData(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error);
      }
    };

    fetchFeaturedProducts();
    fetchFeaturedProducts2();
  }, [user, authenticated]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  const handleHtml = (html) => {
    const cleanHTML = DOMPurify.sanitize(html);
    return cleanHTML;
  };
  const mobile = [
    { imgUrl: "https://i.imgur.com/6mbY3XJ.png" },
    { imgUrl: "https://i.imgur.com/HuNBuFX.png" },
    { imgUrl: "https://i.imgur.com/dyfuXy5.png" },
    { imgUrl: "https://i.imgur.com/QPBSlqL.png" },
  ];

  const renderFeaturedProducts = (position) => {
    return featuredProducts
      .filter((featuredProduct) => {
        if (user) {
          return (
            featuredProduct.position === position &&
            ((user.isCompany && featuredProduct.type === "FOR_BUSINESS") ||
              (!user.isCompany && featuredProduct.type === "FOR_YOU"))
          );
        } else {
          return (
            featuredProduct.position === position &&
            featuredProduct.type === "FOR_YOU"
          );
        }
      })
      .map((filteredProduct) => (
        <CardProdutos
          key={filteredProduct.id} // Adicione uma key única para cada item renderizado
          title={filteredProduct.title}
          data={filteredProduct.products}
          href={filteredProduct.href}
          isHome={true}
        />
      ));
  };

  return (
    <section
      className="xs:pt-[167px] sm:pt-[152px] md:pt-[177px]"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {carrossel.length > 0 && (
        <HeroSection banners={carrossel} mobile={mobile} />
      )}

      {faixaTexto && (
        <div className="py-8 md:px-4 text-xl flex justify-center text-center line-clamp-2">
          <span
            dangerouslySetInnerHTML={{
              __html: `${handleHtml(faixaTexto.text)}`,
            }}
          />
        </div>
      )}

      {featuredProducts && renderFeaturedProducts("TOP")}

      <TiposCabelo data={tiposData} />

      {featuredProducts && renderFeaturedProducts("MID")}

      <Tags />

      {featuredProducts && renderFeaturedProducts("BOTTOM")}

      {banners.map(
        (banner) =>
          banner.position === "MID" && (
            <div key={banner.id}>
              <a href={banner.href}>
                <img
                  src={
                    window.innerWidth <= 760
                      ? "https://i.imgur.com/MIOGlWT.png"
                      : banner.imgUrl
                  }
                  alt={banner.title}
                  className="w-full h-full px-0 lg:px-24 xl:px-44 my-5"
                />
              </a>
            </div>
          )
      )}

      {featuredProducts && renderFeaturedProducts("REVIEW")}

      <div
        className="flex justify-center items-center my-5"
        id="seja-revendedora"
      >
        <div className="w-full">
          <a
            href="https://wa.me/5544997331231?text=Ol%C3%A1,%20quero%20ser%20uma%20representante%20ZP%20Cosm%C3%A9ticos"
            className="block mx-auto"
            target="_blank noopener"
          >
            <img
              src={
                window.innerWidth <= 760
                  ? "https://i.imgur.com/J8y3sWC.png"
                  : "https://i.imgur.com/1TxlLiO.png"
              }
              alt="revendedora"
              className="w-full h-auto px-0 lg:px-24 xl:px-44"
            />
          </a>
        </div>
      </div>

      <NewsLetter />
      <ScrollToTop />
      <WhatsAppButton />
    </section>
  );
};

export default Home;
